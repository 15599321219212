var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"headline",attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"link-icon icon-twitter",staticStyle:{"color":"#00aced"},attrs:{"slot":"activator","fab":"","text":"","href":"https://twitter.com/icchi_h","target":"_blank","rel":"noopener"},slot:"activator"},on),[_c('img',{staticClass:"icon-twitter",attrs:{"src":require("../assets/img/twitter-icon.svg"),"width":"32px","alt":"twitter-icon"}})])]}}])},[_c('span',[_vm._v("Twitter")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"link-icon icon-github",attrs:{"slot":"activator","fab":"","text":"","href":"https://github.com/icchi-h","target":"_blank","rel":"noopener"},slot:"activator"},on),[_c('img',{staticClass:"icon-github",attrs:{"src":require("../assets/img/github-icon.svg"),"width":"32px","alt":"github-icon"}})])]}}])},[_c('span',[_vm._v("GitHub")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"link-icon icon-new-blog-wrap",attrs:{"slot":"activator","fab":"","text":"","href":"https://blog.icchi.me","target":"_blank","rel":"noopener"},slot:"activator"},on),[_c('img',{staticClass:"icon-blog",attrs:{"src":require('../assets/img/blog-icon.svg'),"alt":"blog-icon"}})])]}}])},[_c('span',[_vm._v("Blog")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"link-icon",staticStyle:{"color":"#4cb10d"},attrs:{"slot":"activator","fab":"","text":"","href":"https://qiita.com/icchi_h","target":"_blank","rel":"noopener"},slot:"activator"},on),[_c('img',{staticClass:"icon-qiita",attrs:{"src":require("../assets/img/qiita-icon.png"),"srcset":require("../assets/img/qiita-icon.png") + " 1x, " + require("../assets/img/qiita-icon@2x.png") + " 2x","alt":"qiita-icon"}})])]}}])},[_c('span',[_vm._v("Qiita")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"link-icon",staticStyle:{"color":"#3DA6F8"},attrs:{"slot":"activator","fab":"","text":"","href":"https://zenn.dev/icchi_h","target":"_blank","rel":"noopener"},slot:"activator"},on),[_c('img',{staticClass:"icon-note",attrs:{"src":require("../assets/img/note-icon.png"),"srcset":require("../assets/img/zenn-icon.png") + " 1x, " + require("../assets/img/zenn-icon@2x.png") + " 2x","alt":"note-icon"}})])]}}])},[_c('span',[_vm._v("zenn")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"link-icon",staticStyle:{"color":"#41C9B4"},attrs:{"slot":"activator","fab":"","text":"","href":"https://note.com/icchi_h","target":"_blank","rel":"noopener"},slot:"activator"},on),[_c('img',{staticClass:"icon-note",attrs:{"src":require("../assets/img/note-icon.png"),"srcset":require("../assets/img/note-icon.png") + " 1x, " + require("../assets/img/note-icon@2x.png") + " 2x","alt":"note-icon"}})])]}}])},[_c('span',[_vm._v("note")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }