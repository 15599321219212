import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home.vue";
import Timeline from "../components/Timeline.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: Home },
  { path: "/timeline", component: Timeline },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
